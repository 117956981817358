<template>
  <v-container>
    <div v-html="resource" style="text-align: left"></div>
  </v-container>
</template>

<script>
export default {
  name: "Resource",
  data() {
    return {
      resource: null
    }
  },
  methods: {
    getData() {
      window.axios
          .get('api/resources-value/' + this.$route.params.id)
          .then((res) => {
            this.resource = res.data.data
          })
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    $route() {
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>